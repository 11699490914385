import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/rbac-aks.webp";
import terraform from "../../Assets/Projects/terraform-1024x512.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/helm.png";
import suicide from "../../Assets/Projects/ansible.png";
import bitsOfCode from "../../Assets/Projects/documentation.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Deployment with Helm"
              description="A containerized web application utilizing microservices architecture, deployed on Kubernetes with Helm, managed through automated CI/CD pipelines orchestrated by Jenkins."
              ghLink="https://github.com/ghassenyaa/helmwebapp.git"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Devops Documentation"
              description="I've created a detailed documentation covering Docker, Docker Compose, and Jenkins, with practical examples and tips. Whether you're a developer, DevOps engineer, or just curious about these technologies, this is for you."
              ghLink="https://github.com/ghassenyaa/Devops-Documentation.git"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="English Application"
              description="An English web app developed using React that serves as a comprehensive language learning platform. It offers interactive lessons, quizzes, and real-time speech recognition for pronunciation practice. Users can track their progress, access a vast library of multimedia content. The app employs a responsive design for seamless usage on both desktop and mobile devices."
              ghLink="https://github.com/ghassenyaa/LearnEnglishApp.git"
                          
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title=" End-to-End-DevOps-Azure-Nodejs-Postgres"
              description="Deployed a Node.js app with PostgreSQL on Azure using Terraform for infrastructure as code. Utilized ArgoCD for automated GitOps deployments, set up monitoring with Prometheus, Grafana, and Alertmanager, and implemented a CI/CD pipeline using CircleCI for seamless integration and delivery."
              ghLink="https://github.com/ghassenyaa/-End-to-End-DevOps-Azure-Nodejs-Postgres"

            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="GCP-WITH-TERRAFORM-ANSIBLE"
              description="Automation with Terraform and Ansible revolutionizes infrastructure management. Terraform excels in provisioning and managing cloud resources declaratively, while Ansible automates configuration and application deployments. Together, they enable seamless end-to-end workflows, ensuring consistency, scalability, and efficiency in complex IT environments. This powerful combination reduces human error, accelerates deployments, and simplifies infrastructure-as-code practices."
              ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={terraform}
              isBlog={false}
              title="Terraform Example"
              description="This project demonstrates the power and flexibility of Infrastructure as Code (IaC) with Terraform, allowing seamless provisioning and management of resources across multiple cloud providers. 🌐
            "
              ghLink="https://github.com/ghassenyaa/terraform-example"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
