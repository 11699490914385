import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              
            I have a deep passion for <span className="purple"> DevOps </span> and <span className="purple"> Software Engineering </span> , and over time, I’ve gained significant experience in creating and managing scalable infrastructure. My expertise lies in implementing efficient DevOps pipelines and automation to accelerate delivery and improve collaboration across development and operations teams.


              <br />
              
              
              <br />I am fluent in languages such as <span className="purple"> JavaScript </span> , particularly with its use in modern web technologies. I also have hands-on experience with popular cloud platforms like <span className="purple"> AWS </span> , <span className="purple"> AZURE </span> , and <span className="purple"> GCP </span> , allowing me to manage cloud infrastructure and services efficiently. My skills in containerization with <span className="purple"> DOCKER </span>  and <span className="purple"> KUBERNETES </span>  have further enhanced my ability to build and orchestrate microservices-based architectures seamlessly.


              
              
              <br></br>
              <br />
              I am especially passionate about building innovative products and technologies using Node.js, alongside React.js and Next.js for full-stack development. My focus is always on optimizing workflows, automating tasks, and leveraging the power of modern cloud technologies and <span className="purple"> infrastructure as code (IaC) </span>  practices.
              <br />
              <br></br>
              Always eager to learn and grow, I am continuously exploring new tools and technologies to help create, maintain, and optimize robust systems and services.
              <br />
              <br />
              
              
    





            </p>
          </Col>
          <Col md={4} className="myAvtar">
      
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/ghassenyaa"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/YaakoubiG"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/yaakoubi-ghassen-1b8444198/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/ghassen.yaa"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
